$(document).ready(function () {
	$('.ui.dropdown').dropdown();
	
	$('input[name="transaction-date"]').daterangepicker({
		format: 'MM/DD/YYYY',
		singleDatePicker: true,
		opens: 'right'
	});

	$('input[name="end-date"]').daterangepicker({
		format: 'MM/DD/YYYY',
		singleDatePicker: true,
		opens: 'right'
	});

	$('#upload-input-pdf-button')
		.on('click', function(e) {
    		$('input:file', $(e.target).parents()).click();
	});


	$('#upload-input-pdf-file')
		.on('change', function(e) {
    		var name = e.target.files[0].name;
    		$('#upload-input-pdf-text', $(e.target).parent()).val(name);
	});

	$('#upload-input-img-button')
		.on('click', function(e) {
    		$('input:file', $(e.target).parents()).click();
	});


	$('#upload-input-img-file')
		.on('change', function(e) {
    		var name = e.target.files[0].name;
    		$('#upload-input-img-text', $(e.target).parent()).val(name);
	});


});





